import { useEffect, useState } from "react";
import validate from "../utils/authFormValidation";
import { apiHelper } from "../utils/ApiHelper";
import Toast from "../components/toats";

const Contact = () => {
  const initialFormData = {
    name: "",
    email: "",
    description: "",
  };

  const [validationErrors, setValidationErrors] = useState({});
  const [form, updateForm] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function updateFormHandler(name, value) {
    updateForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function inputChangedHandler(event) {
    // Get name of changed input, and its corresponding value
    const { name, value } = event.target;

    // Update form state against the target input field
    updateFormHandler(name, value);
  }

  async function submitContact(e) {
    const errors = validate(form);
    if (Object.keys(errors).length > 0) {
      setValidationErrors({ ...errors });
      return;
    } else {
      setValidationErrors({});
    }

    const result = await fetch("https://contactapi.yasminek.workers.dev/send-email", {
      method: 'POST', // Place 'method' inside the options object
      headers: {
        'Content-Type': 'application/json' // Corrected 'Content-Type' header and placed in 'headers'
      },
      body: JSON.stringify({
        message: `${form.description} \n\nname: ${form.name}\nEmail: ${form.email}`
      })
    });
    if(result.status === 200){
      Toast.fire({icon: "success",title: "Message submitted successfully!"});
      updateFormHandler('name', '');
      updateFormHandler('email','');
      updateFormHandler('description','');
    }else{
      Toast.fire({icon: "error", title: result.message});
    }
  }

  return (
    <>
      <form>
        <div className="form-group">
          <div className="form-field">
            <input
              type="text"
              name="name"
              className="form-control"
              value={form.name}
              onChange={inputChangedHandler}
              id="name"
              placeholder="Name "
              required
            />
            <span className="errors">{validationErrors?.name}</span>
          </div>
        </div>
        <div className="form-field">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            value={form.email}
            onChange={inputChangedHandler}
            id="email"
            required
          />
          <span className="errors">{validationErrors?.email}</span>
        </div>
        <div className="form-field">
          <textarea
            placeholder="Write a brief messages"
            className="form-control"
            style={{ minHeight: "200px" }}
            name="description"
            value={form.description}
            onChange={inputChangedHandler}
            id="description"
            required
          />
          <span className="errors">{validationErrors?.description}</span>
        </div>

        <button
          className="btn btn-theme"
          type="button"
          onClick={() => submitContact()}
        >
          Send Message
        </button>
      </form>
    </>
  );
};
export default Contact;
