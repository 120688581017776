
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { About } from './pages/About';
import { Careers } from './pages/Careers';
import { Services } from './pages/Services';
import { Solutions } from './pages/Solutions';
import { Faqs } from './pages/FAQs';
import { Contact } from './pages/Contact';
import { Portfolio } from './pages/Portfolio';
import Header from './components/common/header';
import Footer from './components/common/footer';
import Home from './pages/Home/home';
import AOS from 'aos';
import "aos/dist/aos.css";
import { CustomSoftware } from './pages/services/customSoftware';
import { Ai } from './pages/services/ai';
import { MobileDevelopment } from './pages/services/mobileDevelopment';
import { Cloud } from './pages/services/cloud';
import { Database } from './pages/services/database';
import { UiUx } from './pages/services/uiUx';
import { Testing } from './pages/services/testing';
import { Consulting } from './pages/services/consulting';
import { BpaRpa } from './pages/services/bpaRpa';
import Python from './pages/technologies/python';
import DotNet from './pages/technologies/dotNet';
import Php from './pages/technologies/php';
import React from './pages/technologies/react';
import Angular from './pages/technologies/angular';
import Kotlin from './pages/technologies/kotlin';
import Swift from './pages/technologies/swift';
import C from './pages/technologies/c';
import Lamp from './pages/technologies/lamp';
import Unity from './pages/technologies/unity';
import Laravel from './pages/technologies/laravel';
import Django from './pages/technologies/django';

function App() {
    window.scrollTo(0, 0)
    
  AOS.init();
  return (
    <Router>
      <Header />
      <div className='main'>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/custom-software-development" element={<CustomSoftware />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/mobile-app-development" element={<MobileDevelopment />} />
          <Route path="/database-migration" element={<Database />} />
          <Route path="/ui-ux" element={<UiUx />} />
          <Route path="/qa" element={<Testing />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/bpa-rpa" element={<BpaRpa />} />
          <Route path="/python-development" element={<Python />} />
          <Route path="/net-development" element={<DotNet />} />
          <Route path="/php-development" element={<Php />} />
          <Route path="/react-js-development" element={<React />} />
          <Route path="/angular-js-development" element={<Angular />} />
          <Route path="/kotlin-development" element={<Kotlin />} />
          <Route path="/swift-development" element={<Swift />} />
          <Route path="/c-development" element={<C />} />
          <Route path="/lamp-development" element={<Lamp />} />
          <Route path="/unity-development" element={<Unity />} />
          <Route path="/laravel-development" element={<Laravel />} />
          <Route path="/django-development" element={<Django />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
